import React, { useState } from 'react';

const StepIndicator = (props) => {
  const [stepBox] = useState([
    {
      title: 'Mahnkosten & Gebühren',
      icon: 'claim-icon',
    },
    {
      title: 'Interne Zinskosten',
      icon: 'sales-icon',
    },
    {
      title: 'Inkassokosten',
      icon: 'debt-collection-icon',
    },
    {
      title: 'Kosten für collectAI',
      icon: 'calculator-icon',
    },
  ]);

  return (
    <div className="step-warp">
      <div className="row">
        {stepBox.map((box, index) => (
          <div
            className={'three columns step-box ' + checkActiveBox(props.calculatorStep, index)}
            onClick={() => props.onNavigate(index)}
            key={index}
          >
            <div className={"step-box-icon " + box.icon}></div>
            <h2 className="step-box-title">{box.title}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

const checkActiveBox = (step, index) => {
  return step === index ? ' isActive' : '';
};

export default StepIndicator;
