import React, { useState } from 'react';
import InitialDataForm from './forms/initialDataForm';
import Register from './components/register';
import CostsCalculator from './components/costsCalculator';
import InitialDataIndicator from './components/initialDataIndicator';
import DescriptionBox from './components/descriptionBox';
import './skeleton.css';
import './App.css';
import logo from './img/collectAI-logo.svg';
import Icons from './iconSVGs';
import { registerNewUser } from './apis';

const App = () => {
  const [initialData, setInitialData] = useState({
    casesPerMonth: 10000,
    claimAVGAmount: 50,
    industry: 'general',
    dCPShare: 50,
  });

  const [mainSteps, setMainSteps] = useState(0);
  const [calculatorStep, setCalculatorStep] = useState(0);
  const [userEmail, setUserEmail] = useState(null);

  const submitInitialData = () => {
    setMainSteps(mainSteps + 1);
    setCalculatorStep(calculatorStep);
  };

  const startProcess = async (userInfo) => {
    await registerNewUser(userInfo);
    setUserEmail(userInfo.email);
    setInitialData({ ...initialData, industry: userInfo.industry });
    setMainSteps(mainSteps + 1);
    setCalculatorStep(calculatorStep);
  };

  const editInitialData = () => {
    setMainSteps(1);
  };

  const renderMainForms = (step) => {
    if (step === 0) {
      return <Register onSubmit={startProcess} />;
    }
    if (step === 1) {
      return <InitialDataForm setInitialData={setInitialData} initialData={initialData} onClick={submitInitialData} />;
    }
    if (step === 2) {
      return (
        <CostsCalculator
          initialData={initialData}
          setInitialData={setInitialData}
          setMainSteps={setMainSteps}
          calculatorStep={calculatorStep}
          setCalculatorStep={setCalculatorStep}
          userEmail={userEmail}
        />
      );
    }
    return null;
  };

  return (
    <div className="container">
      <div className="cost-saver-warp">
        <div className="header">
          <div className="row">
            <div className="two columns">
              <img className="logo" src={logo} alt="logo" />
            </div>
            {mainSteps === 2 ? (
              <React.Fragment>
                <div className="nine columns">
                  <InitialDataIndicator initialData={initialData} />
                </div>
                <div className="one columns">
                  <button className="button-primary mini-button" onClick={editInitialData}>
                    {Icons.edit}
                  </button>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
        <div className="main">
          <DescriptionBox mainSteps={mainSteps} calculatorStep={calculatorStep} />
          <>{renderMainForms(mainSteps)}</>
        </div>
      </div>
    </div>
  );
};

export default App;
