import React, { useState } from 'react';
import sectionDescriptions from '../presets/sectionDescriptions';

const DescriptionBox = (props) => {
  const [descriptions] = useState(sectionDescriptions);
  if (props.mainSteps === 0) {
    return <div></div>;
  } else {
    return (
      <>
        {props.mainSteps === 1 && props.calculatorStep === 0 ? (
          <div className="description-box-warp">
            <div className="row">
              <div className="description">
                <p>{descriptions.initialDataFromDescription} <a className="description-link" href="mailto:contact@collect.ai">contact@collect.ai</a></p>
              </div>
            </div>
          </div>
        ) : (
          <div className="description-box-warp">
            <div className="row">
              <div className="description">
                <p>{descriptions.calculatorFormsDescription[props.calculatorStep]} <a className="description-link" href="mailto:contact@collect.ai">contact@collect.ai</a></p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default DescriptionBox;
