const sectionDescriptions = {
  initialDataFromDescription:
    'Geben Sie in den nächsten Schritten Ihre Kennzahlen aus dem Forderungsmanagement ein. Alternativ können Sie dabei auch auf branchenübliche Durchschnittswerte zurückgreifen. Auf Grundlage dieser Daten wird im letzten Schritt Ihr Einsparpotential kalkuliert. \n Wenden Sie sich bei Fragen gerne direkt an ',
  calculatorFormsDescription: [
    'Bitte tragen Sie in dieser Übersicht Ihre aktuellen Kosten- und Einnahmen im Mahnwesen ein. Beachten Sie, dass sich die Werte in diesem Schritt auf einzelne Fälle beziehen – die Gesamtkosten und -einnahmen sehen Sie auf der rechten Seite. \n  Wenden Sie sich bei Fragen gerne direkt an ',
    'Bitte tragen Sie in dieser Übersicht Ihre Working-Capital-Kosten durch offene Forderungen sowie die Days Sales Outstanding (DSO) ein. \n Wenden Sie sich bei Fragen gerne direkt an ',
    'Bitte tragen Sie in dieser Übersicht Ihre Kosten im Bereich Inkasso ein. \n Wenden Sie sich bei Fragen gerne direkt an ',
    'Klasse! Hier sehen Sie Ihr Einsparpotential mit collectAI. Passen Sie die Konfiguration gerne nach Ihren Bedürfnissen an und sehen Sie direkt den Einfluss der collectAI Intelligent Payment Plattform auf Ihre Kosten im Forderungsmanagement. \n Bei Fragen steht Ihnen unser Team jederzeit zur Verfügung ',
  ],
};
export default sectionDescriptions;
