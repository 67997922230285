const icons = {
  restart: (
    <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2421 1.7579C9.7056 1.2214 9.0677.794 8.328.4766 7.5884.1588 6.8125 0 6 0c-.7655 0-1.5063.1444-2.2226.4335-.7161.2887-1.353.6967-1.91 1.2228L.8515.6483C.6953.487.5156.4505.3125.539.1041.6275 0 .7813 0 1v3.5002c0 .1354.0495.2526.1484.3516.099.099.2163.1484.3517.1484h3.4998c.2189 0 .3725-.1041.461-.3125.0885-.2031.052-.3828-.1094-.5391L3.2812 3.0704a3.8812 3.8812 0 011.2503-.793c.4688-.185.9583-.2775 1.4687-.2775.5417 0 1.0586.1056 1.5505.3165.4922.211.9179.4961 1.2773.8556.3593.3593.6445.785.8555 1.2773.2108.4922.3163 1.0091.3163 1.5507 0 .5418-.1055 1.0586-.3163 1.5506-.211.4922-.4962.918-.8555 1.2774-.3594.3595-.7852.6446-1.2773.8555-.4922.211-1.0092.3165-1.5505.3165-.6198 0-1.2056-.1354-1.7578-.4062-.552-.2708-1.0181-.6537-1.3983-1.1486-.0364-.052-.0959-.0834-.1797-.0939-.0781 0-.1432.0234-.1953.0703L1.3988 9.5c-.0416.0418-.0638.095-.0664.1601a.279.279 0 00.0509.1758c.5677.6876 1.2551 1.2202 2.0624 1.5977.8073.3776 1.6589.5665 2.5546.5665.8125 0 1.5885-.1589 2.328-.4766.7397-.3177 1.3775-.745 1.914-1.2813.5365-.5366.9638-1.1745 1.2812-1.9142.3177-.7396.4765-1.5158.4765-2.3282 0-.8127-.1589-1.5885-.4766-2.3281-.3178-.7392-.745-1.3773-1.2813-1.9137z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  ),
  average: (
    <svg width="12" height="13" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1 1)" stroke="#FFF" strokeWidth="2" fill="none" fillRule="evenodd">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9.362.61L0 10.581" />
        <circle cx="4.5" cy="5.5" r="4.5" />
      </g>
    </svg>
  ),
  optimizer: (
    <svg width="20" height="13" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M3 1v10.581" />
        <circle fill="#D8D8D8" cx="3" cy="5" r="3" />
        <path stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M10 1v10.581" />
        <circle fill="#D8D8D8" cx="10" cy="7" r="3" />
        <path stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M17 1v10.581" />
        <circle fill="#D8D8D8" cx="17" cy="5" r="3" />
      </g>
    </svg>
  ),
  edit: (
    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.326 2.499l3.05 3.05-7.722 7.723-3.049-3.05 7.72-7.723zm5.365-.736l-1.36-1.36a1.35 1.35 0 00-1.907 0L10.12 1.706l3.051 3.05 1.52-1.52a1.04 1.04 0 000-1.473zM.01 14.51c-.056.25.17.474.42.413l3.399-.824-3.049-3.05-.77 3.461z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  ),
};

export default icons;
