const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  if (!emailRegex.test(email)) {
    return 'Bitte geben Sie eine gültige Email-Adresse ein';
  }
  return undefined;
};

const validateRequiredInputs = (value) => {
  if (!value || value.length <= 1) {
    return 'Dieses Feld ist erforderlich';
  }
  return undefined;
};

export { validateEmail, validateRequiredInputs };
