import { RangeStepInput } from 'react-range-step-input';
import CurrencyInput from 'react-currency-input-field';
import InputsDescriptions from '../presets/inputsDescriptions';

const InitialDataForm = (props) => {
  const handleSliderChange = (event) => {
    props.setInitialData({
      ...props.initialData,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputChange = (value, name) => {
    props.setInitialData({
      ...props.initialData,
      [name]: value,
    });
  };

  return (
    <div className="initial-form">
      <div className="row">
        <div className="section-icon claim-case-icon"></div>
        <h1 className="section-title">Grundeinstellung Mahnwesen</h1>
        <div className="one-third offset-by-one-third column">
          <label>
            Anzahl Mahnfälle pro Monat
            <CurrencyInput
              name="casesPerMonth"
              className="u-full-width"
              value={props.initialData.casesPerMonth}
              onValueChange={handleInputChange}
              allowDecimals={false}
            />
          </label>
          <label>
            Durchschnittliche Forderungshöhe
            <CurrencyInput
              name="claimAVGAmount"
              className="u-full-width"
              value={props.initialData.claimAVGAmount}
              onValueChange={handleInputChange}
              suffix={'  €'}
              groupSeparator="."
              decimalSeparator=","
            />
          </label>
          <label className="input-label">
            Anteil digitaler Kontakdaten
            <span className="range-indicator">{props.initialData.dCPShare}%</span>
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.initialData.dcp}</span>
            </span>
            <RangeStepInput
              name="dCPShare"
              className="u-full-width range-slider"
              min="0"
              max="100"
              step="1"
              value={props.initialData.dCPShare}
              onChange={handleSliderChange}
            />
          </label>
          <button className="button-primary" onClick={() => props.onClick()}>
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
};

export default InitialDataForm;
