import React, { useState } from 'react';
import { RangeStepInput } from 'react-range-step-input';
import CurrencyInput from 'react-currency-input-field';
import InputsDescriptions from '../presets/inputsDescriptions';
import Icons from '../iconSVGs';

const CAIOptimizerForm = (props) => {
  const cAISettings = props.cAISettings;
  const cAISetupConfig = props.cAISetupConfig;
  const setCAISettings = props.setCAISettings;
  const setCAISetupConfig = props.setCAISetupConfig;

  const [modalShow, setModalShow] = useState(false);

  const handleSliderChange = (event) => {
    setCAISetupConfig({
      ...cAISetupConfig,
      [event.target.name]: event.target.value,
    });
  };

  const handleSwitchChange = (event) => {
    const targetState = cAISetupConfig[event.target.name];
    setCAISetupConfig({
      ...cAISetupConfig,
      [event.target.name]: !targetState,
    });
  };

  function openModal() {
    setModalShow(true);
  }

  function closeModal() {
    setModalShow(false);
  }

  const handleOptSliderChange = (event) => {
    setCAISettings({
      ...cAISettings,
      [event.target.name]: event.target.value,
    });
  };

  const handleCostInputChange = (value, name) => {
    setCAISettings({
      ...cAISettings,
      [name]: value,
    });
  };

  return (
    <div className="forms-warp">
      <div className="row">
        <div className="section-icon calculator-icon"></div>
        <h1 className="section-title">Kosten für collectAI</h1>
        <div className="ten offset-by-one columns opt-setting">
          <h5>Berechnungsgrundlage</h5>
          <button className="button-primary mini-button" onClick={openModal}>
            {Icons.optimizer}
          </button>
        </div>
        <div className="ten offset-by-one columns">
          <h5 className="form-subsection-title">Kommunikationskanäle</h5>
          <label>
            Anzahl der E-Mails
            <span className="range-indicator">{cAISetupConfig.numberOfEmails}</span>
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.cAISetupConfig.numberOfEmails}</span>
            </span>
            <RangeStepInput
              name="numberOfEmails"
              className="u-full-width range-slider"
              min="0"
              max="12"
              value={cAISetupConfig.numberOfEmails}
              onChange={handleSliderChange}
            />
          </label>
          <label>
            Anzahl der SMS
            <span className="range-indicator">{cAISetupConfig.numberOfSMS}</span>
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.cAISetupConfig.numberOfSMS}</span>
            </span>
            <RangeStepInput
              name="numberOfSMS"
              className="u-full-width range-slider"
              min="0"
              max="12"
              value={cAISetupConfig.numberOfSMS}
              onChange={handleSliderChange}
            />
          </label>
          <label>
            Anzahl Briefe
            <span className="range-indicator">{cAISetupConfig.numberOfLetters}</span>
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.cAISetupConfig.numberOfLetters}</span>
            </span>
            <RangeStepInput
              name="numberOfLetters"
              className="u-full-width range-slider"
              min="0"
              max="12"
              value={cAISetupConfig.numberOfLetters}
              onChange={handleSliderChange}
            />
          </label>
          <h5 className="form-subsection-title">Aktive Payments</h5>
          <div className="switch-warp">
            <span className="switch-label">PayPal</span>
            <label className="switch">
              <input name="paypal" type="checkbox" checked={cAISetupConfig.paypal} onChange={handleSwitchChange} />
              <span className="slider"></span>
            </label>
          </div>
          <div className="switch-warp">
            <span className="switch-label">PSP</span>
            <label className="switch">
              <input name="pSP" type="checkbox" checked={cAISetupConfig.pSP} onChange={handleSwitchChange} />
              <span className="slider"></span>
            </label>
          </div>
          <div className="switch-warp">
            <span className="switch-label">Direktüberweisung</span>
            <label className="switch">
              <input
                name="directTransfer"
                type="checkbox"
                checked={cAISetupConfig.directTransfer}
                onChange={handleSwitchChange}
              />
              <span className="slider"></span>
            </label>
          </div>
          <div className="switch-warp">
            <span className="switch-label">Sofort</span>
            <label className="switch">
              <input name="sofort" type="checkbox" checked={cAISetupConfig.sofort} onChange={handleSwitchChange} />
              <span className="slider"></span>
            </label>
          </div>
          <div className="switch-warp">
            <span className="switch-label">Kreditkarte</span>
            <label className="switch">
              <input
                name="creditCard"
                type="checkbox"
                checked={cAISetupConfig.creditCard}
                onChange={handleSwitchChange}
              />
              <span className="slider"></span>
            </label>
          </div>
          <div className="switch-warp">
            <span className="switch-label">Sonstige</span>
            <label className="switch">
              <input name="other" type="checkbox" checked={cAISetupConfig.other} onChange={handleSwitchChange} />
              <span className="slider"></span>
            </label>
          </div>
          {modalShow === true ? (
            <div className="modal">
              <div className="modal-content">
                <button className="close-button" onClick={closeModal}></button>
                <div className="eight offset-by-two columns">
                  <h4> Mit Branchenwerten ausfüllen </h4>
                </div>
                <div className="optimizer-modal">
                  <div className="row">
                    <div className="eight offset-by-two inputs-group columns">
                      <p className="modal-description">
                        Nachfolgend sehen Sie die zur Berechnung verwendeten Daten. Passen Sie diese auf Ihren konkreten
                        Anwendungsfall an, um noch präzisere Ergebnisse zu erzielen
                      </p>
                      <h5>Kommunikationskosten</h5>
                      <hr />
                      <div className="row">
                        <div className="five columns">
                          <label>Kosten pro E-mail</label>
                        </div>
                        <div className="three columns">
                          <CurrencyInput
                            name="emailCost"
                            className="optimizer-input u-full-width"
                            value={cAISettings.emailCost}
                            disabled={true}
                            prefix={'€'}
                            groupSeparator="."
                            decimalSeparator=","
                            decimalsLimit={2}
                            maxLength={4}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="five columns">
                          <label>Kosten pro SMS</label>
                        </div>
                        <div className="three columns">
                          <CurrencyInput
                            name="sMScost"
                            className="optimizer-input u-full-width"
                            value={cAISettings.sMScost}
                            disabled={true}
                            prefix={'€'}
                            groupSeparator="."
                            decimalSeparator=","
                            decimalsLimit={2}
                            maxLength={4}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="five columns">
                          <label>Kosten pro Letter</label>
                        </div>
                        <div className="three columns">
                          <CurrencyInput
                            name="letterCost"
                            className="optimizer-input u-full-width"
                            value={cAISettings.letterCost}
                            disabled={true}
                            prefix={'€'}
                            groupSeparator="."
                            decimalSeparator=","
                            decimalsLimit={2}
                            maxLength={4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="eight offset-by-two inputs-group columns">
                      <h5>Payment-Kosten</h5>
                      <hr />
                      <div className="row">
                        <div className="five columns">
                          <label>Direktüberweisung</label>
                        </div>
                        <div className="three columns">
                          <CurrencyInput
                            name="directTransfer"
                            className="optimizer-input u-full-width"
                            value={cAISettings.directTransfer}
                            onValueChange={handleCostInputChange}
                            prefix={'€'}
                          />
                        </div>
                        <div className="four columns">
                          <span className="post-input-des"> pro Transaktions </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="five columns">
                          <label>
                            Paypal
                            <span className="range-indicator">{cAISettings.paypal}%</span>
                          </label>
                        </div>
                        <div className="three columns">
                          <RangeStepInput
                            name="paypal"
                            className="optimizer-input optimizer-range u-full-width range-slider"
                            min="0"
                            max="10"
                            step="0.1"
                            value={cAISettings.paypal}
                            onChange={handleOptSliderChange}
                          />
                        </div>
                        <div className="four columns">
                          <span className="post-input-des"> vom Transaktionsvolumen </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="five columns">
                          <label>
                            Sofort
                            <span className="range-indicator">{cAISettings.sofort}%</span>
                          </label>
                        </div>
                        <div className="three columns">
                          <RangeStepInput
                            name="sofort"
                            className="optimizer-input optimizer-range u-full-width range-slider"
                            min="0"
                            max="10"
                            step="0.1"
                            value={cAISettings.sofort}
                            onChange={handleOptSliderChange}
                          />
                        </div>
                        <div className="four columns">
                          <span className="post-input-des"> vom Transaktionsvolumen </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="five columns">
                          <label>
                            Kreditkarte
                            <span className="range-indicator">{cAISettings.creditCard}%</span>
                          </label>
                        </div>
                        <div className="three columns">
                          <RangeStepInput
                            name="creditCard"
                            className="optimizer-input optimizer-range u-full-width range-slider"
                            min="0"
                            max="10"
                            step="0.1"
                            value={cAISettings.creditCard}
                            onChange={handleOptSliderChange}
                          />
                        </div>
                        <div className="four columns">
                          <span className="post-input-des"> vom Transaktionsvolumen </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="five columns">
                          <label>PSP</label>
                        </div>
                        <div className="three columns">
                          <CurrencyInput
                            name="pSP"
                            className="optimizer-input u-full-width"
                            value={cAISettings.pSP}
                            onValueChange={handleCostInputChange}
                            prefix={' €'}
                            groupSeparator="."
                            decimalSeparator=","
                            decimalsLimit={2}
                            maxLength={4}
                          />
                        </div>
                        <div className="four columns">
                          <span className="post-input-des"> vom Transaktionsvolumen </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="five columns">
                          <label>
                            Sonstige
                            <span className="range-indicator">{cAISettings.other}%</span>
                          </label>
                        </div>
                        <div className="three columns">
                          <RangeStepInput
                            name="other"
                            className="optimizer-input optimizer-range u-full-width range-slider"
                            min="0"
                            max="10"
                            step="0.1"
                            value={cAISettings.other}
                            onChange={handleOptSliderChange}
                          />
                        </div>
                        <div className="four columns">
                          <span className="post-input-des"> vom Transaktionsvolumen </span>
                        </div>
                      </div>
                    </div>
                    <div className="eight offset-by-two inputs-group columns">
                      <h5>Prozessoptimierung</h5>
                      <hr />
                      <div className="row">
                        <div className="five columns">
                          <label>
                            Prozesskosten
                            <span className="range-indicator">{cAISettings.processOpt}%</span>
                          </label>
                        </div>
                        <div className="three columns">
                          <RangeStepInput
                            name="processOpt"
                            className="optimizer-input optimizer-range u-full-width range-slider"
                            min="0"
                            max="100"
                            step="1"
                            value={cAISettings.processOpt}
                            onChange={handleOptSliderChange}
                          />
                        </div>
                        <div className="four columns">
                          <span className="post-input-des"> Optimierung </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="five columns">
                          <label>
                            Kosten im Callcenter
                            <span className="range-indicator">{cAISettings.callCenterCostOpt}%</span>
                          </label>
                        </div>
                        <div className="three columns">
                          <RangeStepInput
                            name="callCenterCostOpt"
                            className="optimizer-input optimizer-range u-full-width range-slider"
                            min="0"
                            max="100"
                            step="1"
                            value={cAISettings.callCenterCostOpt}
                            onChange={handleOptSliderChange}
                          />
                        </div>
                        <div className="four columns">
                          <span className="post-input-des"> Optimierung </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="five columns">
                          <label>
                            Days Sales Outstanding
                            <span className="range-indicator">{cAISettings.daysSalesOutstandingOpt}%</span>
                          </label>
                        </div>
                        <div className="three columns">
                          <RangeStepInput
                            name="daysSalesOutstandingOpt"
                            className="optimizer-input optimizer-range u-full-width range-slider"
                            min="0"
                            max="100"
                            step="1"
                            value={cAISettings.daysSalesOutstandingOpt}
                            onChange={handleOptSliderChange}
                          />
                        </div>
                        <div className="four columns">
                          <span className="post-input-des"> Optimierung </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="five columns">
                          <label>
                            Inkasso Anteils
                            <span className="range-indicator">{cAISettings.debtCollectionShareOpt}%</span>
                          </label>
                        </div>
                        <div className="three columns">
                          <RangeStepInput
                            name="debtCollectionShareOpt"
                            className="optimizer-input optimizer-range u-full-width range-slider"
                            min="0"
                            max="100"
                            step="1"
                            value={cAISettings.debtCollectionShareOpt}
                            onChange={handleOptSliderChange}
                          />
                        </div>
                        <div className="four columns">
                          <span className="post-input-des"> Optimierung </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="button-primary button-modal" onClick={closeModal}>
                  Speichern
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CAIOptimizerForm;
