// import axios from 'axios';
import { saveAs } from 'file-saver';
import { createPDF } from '../apis';

const ExportBox = (props) => {
  const {
    userEmail,
    casesPerMonth,
    claimAVGAmount,
    dCPShare,
    dunningFees,
    dunningCosts,
    salesData,
    debtCollectionData,
    calculatedAmounts,
    cAIPrice,
    totalCosts,
    costPerCase,
    savingPotential,
    activePayments,
    cAISetupConfig,
    onDownload,
  } = props;

  const exportData = {
    userEmail,
    casesPerMonth,
    claimAVGAmount,
    dCPShare,
    dunningFees,
    dunningCosts,
    salesData,
    debtCollectionData,
    calculatedAmounts,
    cAIPrice,
    totalCosts,
    costPerCase,
    savingPotential,
    activePayments,
    cAISetupConfig,
  };

  const handleDownload = async () => {
    const resBlob = await createPDF(exportData);
    const pdfBlob = new Blob(resBlob, { type: 'application/pdf' });
    saveAs(pdfBlob, 'cAI-costs.pdf');
    onDownload();
  };
  return (
    <div className="export-box-warp">
      <h5 className="section-title">PDF Export</h5>
      <div className="row">
        <div className="seven columns">
          <p>Laden Sie Ihre Kalkulation als PDF herunter</p>
        </div>
        <div className="five columns">
          <button className="button-primary export-button" onClick={handleDownload}>
            Herunterladen
          </button>
        </div>
      </div>
    </div>
  );
};
export default ExportBox;
