import CurrencyInput from 'react-currency-input-field';
import InputsDescriptions from '../presets/inputsDescriptions';
import Icons from '../iconSVGs';

const DunningFeesCostsForm = (props) => {
  const dunningCosts = props.dunningCosts;
  const setDunningCosts = props.setDunningCosts;
  const dunningFees = props.dunningFees;
  const setDunningFees = props.setDunningFees;

  const handleCostInputChange = (value, name) => {
    setDunningCosts({
      ...dunningCosts,
      [name]: value,
    });
  };

  const handleFeeInputChange = (value) => {
    setDunningFees(value);
  };

  const assignRelatedDefaultData = () => {
    setDunningFees(props.selectedIndustryData.dunningFees);
    setDunningCosts(props.selectedIndustryData.dunningCosts);
  };
  
  return (
    <div className="forms-warp">
      <div className="row">
        <div className="section-icon claim-icon"></div>
        <h1 className="section-title">Mahnkosten & Gebühren</h1>
        <div className="ten offset-by-one columns assign-default-data">
          <h5> Mit Branchenwerten ausfüllen </h5>
          <button className="button-primary mini-button" onClick={assignRelatedDefaultData}>{Icons.average}</button>
        </div>
        <div className="ten offset-by-one columns">
          <label>
            Kommunikationskosten
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.dunningCosts.communicationCost}</span>
            </span>
            <CurrencyInput
              name="communicationCost"
              className="u-full-width"
              value={dunningCosts.communicationCost}
              onValueChange={handleCostInputChange}
              suffix={'  €'}
              groupSeparator="."
              decimalSeparator=","
              decimalsLimit={2}
              maxLength={4}
            />
          </label>
          <label>
            Prozesskosten
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.dunningCosts.communicationCost}</span>
            </span>
            <CurrencyInput
              name="processCost"
              className="u-full-width"
              value={dunningCosts.processCost}
              onValueChange={handleCostInputChange}
              suffix={'  €'}
              groupSeparator="."
              decimalSeparator=","
            />
          </label>
          <label>
            Kosten im Callcenter
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.dunningCosts.callCenterCost}</span>
            </span>
            <CurrencyInput
              name="callCenterCost"
              className="u-full-width"
              value={dunningCosts.callCenterCost}
              onValueChange={handleCostInputChange}
              suffix={'  €'}
              groupSeparator="."
              decimalSeparator=","
            />
          </label>
          <label>
            Mahngebühren
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.dunningFees}</span>
            </span>
            <CurrencyInput
              name="dunningFees"
              value={dunningFees}
              decimalsLimit={2}
              className="u-full-width"
              onValueChange={handleFeeInputChange}
              suffix={'  €'}
              groupSeparator="."
              decimalSeparator=","
            />
          </label>
          <label>
            Sonstige Kosten
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.dunningCosts.otherCost}</span>
            </span>
            <CurrencyInput
              name="otherCost"
              className="u-full-width"
              value={dunningCosts.otherCost}
              onValueChange={handleCostInputChange}
              suffix={'  €'}
              groupSeparator="."
              decimalSeparator=","
            />
          </label>
          <button className="button-primary" onClick={() => props.onClick()}>
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
};

export default DunningFeesCostsForm;
