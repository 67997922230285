const initialSettings = {
  dCPShare: 50,
  processOpt: 40,
  callCenterCostOpt: 80,
  daysSalesOutstandingOpt: 40,
  debtCollectionShareOpt: 20,
  emailCost: 0.01,
  sMScost: 0.16,
  letterCost: 1.2,
  directTransfer: 0.3,
  paypal: 2.4,
  sofort: 0.9,
  pSP: 0.5,
  creditCard: 1.9,
  other: 1,
  otherCosts: 0.8,
  cAICost: 0.95,
};
const initialSetupConfig = {
  numberOfEmails: 2,
  numberOfSMS: 2,
  numberOfLetters: 1,
  directTransfer: true,
  sofort: false,
  creditCard: false,
  other: false,
  paypal: true,
  pSP: false,
};

export { initialSettings, initialSetupConfig };
