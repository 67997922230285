import CurrencyInput from 'react-currency-input-field';
import { RangeStepInput } from 'react-range-step-input';
import InputsDescriptions from '../presets/inputsDescriptions';
import Icons from '../iconSVGs';

const DebtCollectionCostsForm = (props) => {
  const debtCollectionData = props.debtCollectionData;
  const setDebtCollectionData = props.setDebtCollectionData;

  const handleInputChange = (value, name) => {
    setDebtCollectionData({
      ...debtCollectionData,
      [name]: value,
    });
  };

  const handleSliderChange = (event) => {
    setDebtCollectionData({
      ...debtCollectionData,
      [event.target.name]: event.target.value,
    });
  };

  const assignRelatedDefaultData = () => {
    setDebtCollectionData(props.selectedIndustryData.debtCollectionData);
  };

  

  return (
    <div className="forms-warp">
      <div className="row">
        <div className="section-icon debt-collection-icon"></div>
        <h1 className="section-title">Inkassokosten</h1>
        <div className="ten offset-by-one columns assign-default-data">
          <h5> Mit Branchenwerten ausfüllen </h5>
          <button className="button-primary mini-button" onClick={assignRelatedDefaultData}>{Icons.average}</button>
        </div>
        <div className="ten offset-by-one columns">
          <label>
            Inkasso Anteil
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.debtCollectionData.debtCollectionShare}</span>
            </span>
            <span className="range-indicator">{debtCollectionData.debtCollectionShare}%</span>
            <RangeStepInput
              name="debtCollectionShare"
              className="u-full-width range-slider"
              min="0"
              max="100"
              value={debtCollectionData.debtCollectionShare}
              onChange={handleSliderChange}
            />
          </label>
          <label>
            Inkassokosten pro Fall
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.debtCollectionData.debtCollectionCostPerCase}</span>
            </span>
            <CurrencyInput
              name="debtCollectionCostPerCase"
              className="u-full-width"
              value={debtCollectionData.debtCollectionCostPerCase}
              onValueChange={handleInputChange}
              suffix={' €'}
              groupSeparator="."
              decimalSeparator=","
            />
          </label>
          <label>
            Customer Akquisition Costs
            <CurrencyInput
              name="cAC"
              className="u-full-width"
              value={debtCollectionData.cAC}
              onValueChange={handleInputChange}
              suffix={' €'}
              groupSeparator="."
              decimalSeparator=","
            />
          </label>
          <button className="button-primary" onClick={() => props.onClick()}>
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
};

export default DebtCollectionCostsForm;
