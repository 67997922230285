import CurrencyInput from 'react-currency-input-field';
import { RangeStepInput } from 'react-range-step-input';
import InputsDescriptions from '../presets/inputsDescriptions';
import Icons from '../iconSVGs';

const SalesDataForm = (props) => {
  const salesData = props.salesData;
  const setSalesData = props.setSalesData;

  const handleInputChange = (value, name) => {
    setSalesData({
      ...salesData,
      [name]: value,
    });
  };

  const handleSliderChange = (event) => {
    setSalesData({
      ...salesData,
      [event.target.name]: event.target.value,
    });
  };

  const assignRelatedDefaultData = () => {
    setSalesData(props.selectedIndustryData.salesData);
  };


  return (
    <div className="forms-warp">
      <div className="row">
        <div className="section-icon sales-icon"></div>
        <h1 className="section-title">Interne Zinskosten</h1>
        <div className="ten offset-by-one columns assign-default-data">
          <h5> Mit Branchenwerten ausfüllen </h5>
          <button className="button-primary mini-button" onClick={assignRelatedDefaultData}>{Icons.average}</button>
        </div>
        <div className="ten offset-by-one columns">
          <label>
            Days Sales Outstanding
            <span className="input-tooltip">
              <span className="tooltip-text">{InputsDescriptions.salesData.daysSalesOutstanding}</span>
            </span>
            <CurrencyInput
              name="daysSalesOutstanding"
              className="u-full-width"
              value={salesData.daysSalesOutstanding}
              onValueChange={handleInputChange}
              allowDecimals={false}
            />
          </label>
          <label>
            Interner Zinssatz für Working Capital
            <span className="range-indicator">{salesData.capitalRate}%</span>
            <RangeStepInput
              name="capitalRate"
              className="u-full-width range-slider"
              min="0"
              max="100"
              value={salesData.capitalRate}
              onChange={handleSliderChange}
            />
          </label>
          <button className="button-primary" onClick={() => props.onClick()}>
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
};

export default SalesDataForm;
