const defaultData = {
  general: {
    dunningFees: 2.5,
    dunningCosts: {
      communicationCost: 2.6,
      processCost: 4.6,
      callCenterCost: 3.2,
      otherCost: 0.8,
    },
    salesData: {
      daysSalesOutstanding: 25,
      capitalRate: 5.0,
    },
    debtCollectionData: {
      debtCollectionShare: 6.0,
      debtCollectionCostPerCase: 22.5,
      cAC: 250.0,
    },
  },
  insurance: {
    dunningFees: 2.5,
    dunningCosts: {
      communicationCost: 3.1,
      processCost: 5.6,
      callCenterCost: 3.2,
      otherCost: 1.1,
    },
    salesData: {
      daysSalesOutstanding: 30,
      capitalRate: 5.0,
    },
    debtCollectionData: {
      debtCollectionShare: 3.0,
      debtCollectionCostPerCase: 22.5,
      cAC: 280.0,
    },
  },
  eComm: {
    dunningFees: 1.8,
    dunningCosts: {
      communicationCost: 1.8,
      processCost: 4.5,
      callCenterCost: 2.9,
      otherCost: 0.7,
    },
    salesData: {
      daysSalesOutstanding: 22,
      capitalRate: 5.0,
    },
    debtCollectionData: {
      debtCollectionShare: 4.0,
      debtCollectionCostPerCase: 22.5,
      cAC: 220.0,
    },
  },
  banking: {
    dunningFees: 3.0,
    dunningCosts: {
      communicationCost: 5.3,
      processCost: 6.5,
      callCenterCost: 5.4,
      otherCost: 1.2,
    },
    salesData: {
      daysSalesOutstanding: 35,
      capitalRate: 5.0,
    },
    debtCollectionData: {
      debtCollectionShare: 5.0,
      debtCollectionCostPerCase: 22.5,
      cAC: 250.0,
    },
  },
  media: {
    dunningFees: 5.0,
    dunningCosts: {
      communicationCost: 4.1,
      processCost: 3.5,
      callCenterCost: 3.0,
      otherCost: 0.8,
    },
    salesData: {
      daysSalesOutstanding: 22,
      capitalRate: 5.0,
    },
    debtCollectionData: {
      debtCollectionShare: 6.0,
      debtCollectionCostPerCase: 22.5,
      cAC: 190.0,
    },
  },
  energy: {
    dunningFees: 1.5,
    dunningCosts: {
      communicationCost: 4.2,
      processCost: 4.8,
      callCenterCost: 3.6,
      otherCost: 1.3,
    },
    salesData: {
      daysSalesOutstanding: 25,
      capitalRate: 5.0,
    },
    debtCollectionData: {
      debtCollectionShare: 6.0,
      debtCollectionCostPerCase: 22.5,
      cAC: 320.0,
    },
  },
  telCom: {
    dunningFees: 1.8,
    dunningCosts: {
      communicationCost: 3.3,
      processCost: 4.5,
      callCenterCost: 2.4,
      otherCost: 0.7,
    },
    salesData: {
      daysSalesOutstanding: 23,
      capitalRate: 5.0,
    },
    debtCollectionData: {
      debtCollectionShare: 7.0,
      debtCollectionCostPerCase: 22.5,
      cAC: 310.0,
    },
  },
};

export default defaultData;
