const inputsDescriptions = {
  initialData: {
    dcp: "Anteil Ihrer Kund*innen, von denen digitale Kontaktdaten wie E-Mail-Adresse oder Mobiltelefonnummer vorliegen",
  },
  dunningFees: "ø Mahngebühren pro Fall über den gesamten Mahnprozess.",
  dunningCosts: {
    communicationCost: 'Kommunikationskosten inkl. Porto, Kuvertierung, Handling, Rückläufer',
    processCost:
      'Kosten für manuelle Prozesse inkl. Zahlungsabwicklung, Einwandbehandlung, Debitorenbuchhaltung, Steuerung Mahnläufe etc.',
    callCenterCost: "Kosten durch in- und outbound Calls im Callcenter.",
    otherCost: "Kosten für Anpassungen und Betrieb der IT-Infrastruktur.",
  },
  salesData: {
    daysSalesOutstanding: "Durchschnittliche Anzahl an Tagen, bis eine offene Forderung beigetrieben ist.",
   
  },
  debtCollectionData: {
    debtCollectionShare: "Anteil der Mahnfälle, die in das Inkasso gehen in %.",
    debtCollectionCostPerCase: "Ggf. Auslagen, die für den Inkassoprozess nicht durch das Inkasso-Unternehmen gedeckt werden",
  },
  cAISetupConfig: {
    numberOfEmails: "ø #Emails á 0,001 €",
    numberOfSMS: "ø #SMS á 0,16 €",
    numberOfLetters: "ø #Briefe á 1,20 €",
  }
};
export default inputsDescriptions;
