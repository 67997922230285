import React, { useState } from 'react';
import { validateEmail, validateRequiredInputs } from '../utils/userValidation';

const Register = (props) => {
  const { onSubmit } = props;

  const initialUserInfo = {
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    industry: 'general',
  };

  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const [userInfoErrors, setUserInfoErrors] = useState({
    email: undefined,
    company: undefined,
  });

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleOnClick = () => {
    const emailError = validateEmail(userInfo.email);
    const companyError = validateRequiredInputs(userInfo.company);
    if (!emailError && !companyError) {
      onSubmit(userInfo);
    }
    setUserInfoErrors({
      email: emailError,
      company: companyError,
    });
  };

  return (
    <div className="introduction-warp">
      <div className="row">
        <div className="ten offset-by-one columns opt-setting">
          <h1 className="main-title">Cost Saver</h1>
        </div>
      </div>
      <div className="row">
        <div className="twelve column"></div>
        <div className="six columns">
          <div className="section-icon intro-icon"></div>
          <p>
            Wir transformieren Rechnungen und Mahnwesen mit Künstlicher Intelligenz in ein positives Kundenerlebnis –
            interaktiv, intelligent, innovativ. Wir gestalten mit KI-basierten Lösungen das digitale
            Forderungsmanagement der Zukunft.
          </p>
          <p>
            Mit unserer KI-basierten Intelligent Payment Plattform erzielen Unternehmen verschiedener Branchen messbare
            Ergebnisse – die Kund*innenbeziehung wird verbessert, die Realisierungsquote steigt und die Kosten werden
            gesenkt.
          </p>
          <p>Berechnen Sie jetzt mit dem collectAI Cost Saver Ihr Einsparpotential im Forderungsmanagement.</p>
        </div>
        <div className="five columns">
          <div className="user-register-from">
            <label>
              Email *
              <input
                name="email"
                type="text"
                className="u-full-width"
                value={userInfo.email}
                onChange={handleOnChange}
              />
              <p className="text-error">{userInfoErrors.email}</p>
            </label>
            <label>
              Vorname
              <input
                name="firstName"
                type="text"
                className="u-full-width"
                value={userInfo.firstName}
                onChange={handleOnChange}
              />
            </label>
            <label>
              Nachname
              <input
                name="lastName"
                className="u-full-width"
                type="text"
                value={userInfo.lastName}
                onChange={handleOnChange}
              />
            </label>
            <label>
              Unternehmensname *
              <input
                name="company"
                className="u-full-width"
                type="text"
                value={userInfo.company}
                onChange={handleOnChange}
              />
              <p className="text-error">{userInfoErrors.company}</p>
            </label>
            <label>
              Branche *
              <select
                className="u-full-width cs-select"
                name="industry"
                value={userInfo.industry}
                onChange={handleOnChange}
              >
                <option value="general">General</option>
                <option value="insurance">Versicherung</option>
                <option value="eComm">E-Commerce</option>
                <option value="banking">Banking</option>
                <option value="media">Medien</option>
                <option value="energy">Energie</option>
                <option value="telCom">Telco</option>
              </select>
            </label>
          </div>
        </div>
        <div className="ten columns">
          <button className="button-primary start-button" onClick={handleOnClick}>
            Los geht's
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
