import Icons from '../iconSVGs';


const RestartCalculations = (props) => {
  return (
    <div className="reset-box-warp">
      <div className="row">
        <h5 className="button-section-title reset-title">Von vorn anfangen</h5>
        <button onClick={() => props.onRestart()} className="button-primary mini-button">
        {Icons.restart}
        </button>
      </div>
    </div>
  );
};
export default RestartCalculations;
