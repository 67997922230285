const InitialDataIndicator = (props) => {
  return (
    <div className="initial-data">
      <div className="head-data-block cases-per-month">
        Fälle pro Monat: <span>{props.initialData.casesPerMonth}</span>
      </div>
      <div className="head-data-block average-case-amount">
        Durchschn. Ford.Höhe: <span>{props.initialData.claimAVGAmount} €</span>
      </div>
      {/* <div className="head-data-block industry">
        Branche: <span>{props.initialData.industry}</span>
      </div> */}
      <div className="head-data-block Industry">
        DKP: <span>{props.initialData.dCPShare} %</span>
      </div>
    </div>
  );
};

export default InitialDataIndicator;
