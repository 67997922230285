import axios from 'axios';

const registerNewUser = async (userInfo) => {
  try {
    await axios.post('/register', userInfo);
  } catch (e) {
    throw new Error(`Error while registering the user: ${e} `);
  }
};

const createPDF = async (exportData) => {
  try {
    const res = await axios.post('/create-pdf', exportData, { responseType: 'blob' });
    return [res.data];
  } catch (e) {
    throw new Error(`Error while registering the user: ${e} `);
  }
};

export { registerNewUser, createPDF };
