import React, { useState, useEffect } from 'react';


const NumberBlocks = (props) => {
  
  const calculatedAmounts = props.calculatedAmounts;
  const sepratorFix = (number) => {
     return (number.toString().replace('.',',')).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  useEffect(() => {
    
    props.calculatorStep === 3 ? props.setCAIOptimizerIsActivated(true) : props.setCAIOptimizerIsActivated(false);

    setRevenues([
      {
        title: 'Mahngebühren',
        amount: sepratorFix(calculatedAmounts.dunningFees.amount),
        cAIAmount: sepratorFix(calculatedAmounts.dunningFees.cAIAmount),
      },
    ]);
    setCosts([
      {
        title: 'Mahnkosten',
        amount: sepratorFix(calculatedAmounts.dunningCosts.amount),
        cAIAmount: sepratorFix(calculatedAmounts.dunningCosts.cAIAmount),
      },
      {
        title: 'Interne Zinskosten',
        amount: sepratorFix(calculatedAmounts.salesData.amount),
        cAIAmount: sepratorFix(calculatedAmounts.salesData.cAIAmount),
      },
      {
        title: 'Inkassokosten (Auslagen)',
        amount: sepratorFix(calculatedAmounts.debtCollection.amount),
        cAIAmount: sepratorFix(calculatedAmounts.debtCollection.cAIAmount),
      },
      {
        title: 'Kosten Kund*innenverlust',
        amount: sepratorFix(calculatedAmounts.cACDebtCollection.amount),
        cAIAmount: sepratorFix(calculatedAmounts.cACDebtCollection.cAIAmount),
      },
    ]);

    setSum({
      title: 'Summe der Kosten',
      amount: sepratorFix(props.totalCosts.amount.toFixed(2)),
      cAIAmount: sepratorFix(props.totalCosts.cAIAmount.toFixed(2)),
    });

    setClaimCost({
      title: 'Kosten pro Mahnfall',
      amount: sepratorFix(props.costPerCase.amount.toFixed(2)),
      cAIAmount: sepratorFix(props.costPerCase.cAIAmount.toFixed(2)),
    });
  }, [calculatedAmounts, props]);

  const [revenues, setRevenues] = useState([{ title: 'Mahngebühren', amount: 0, cAIAmount: 0 }]);
  const [costs, setCosts] = useState([
    { title: 'Mahnkosten', amount: 0, cAIAmount: 0 },
    { title: 'Interne Zinskosten', amount: 0, cAIAmount: 0 },
    { title: 'Inkassokosten (Auslagen)', amount: 0, cAIAmount: 0 },
    { title: 'Kosten Kund*innenverlust', amount: 0, cAIAmount: 0 },
  ]);
  const [sum, setSum] = useState({ title: 'Summe der Kosten', amount: 0, cAIAmount: 0 });
  const [claimCost, setClaimCost] = useState({ title: 'Kosten pro Mahnfall', amount: 0, cAIAmount: 0 });

  const checkRowIsActive = (index) => {
    return props.calculatorStep === 0 && index === 0
      ? ' active-block'
      : props.calculatorStep === 1 && index === 1
      ? ' active-block'
      : props.calculatorStep === 2 && (index === 2 ||  index === 3)
      ? ' active-block'
      : props.cAIOptimizerIsActivated === true
      ? ' active-block'
      : '';
  };


  return (
    <div className="number-blocks">
      <h5 className="blocks-title">Einnahmen</h5>
      {revenues.map((revenueBlock, index) => (
        <div className={'row number-block' + checkRowIsActive(index)} key={index}>
          <div className="five columns number-block-title">
            <span className="title">{revenueBlock.title}</span>
          </div>
          <div className="seven columns number-block-amount">
            <span className="amount">{revenueBlock.amount} €</span>
            {props.cAIOptimizerIsActivated === true ? (
              <span className="CAI-amount">{revenueBlock.cAIAmount} €</span>
            ) : null}
          </div>
        </div>
      ))}
      <h5 className="blocks-title">Kosten</h5>
      {costs.map((costBlock, index) => (
        <div className={'row number-block' + checkRowIsActive(index)} key={index}>
          <div className="five columns number-block-title">
            <span className="title">{costBlock.title}</span>
          </div>
          <div className="seven columns number-block-amount">
            <span className="amount">{costBlock.amount} €</span>
            {props.cAIOptimizerIsActivated === true ? <span className="CAI-amount">{costBlock.cAIAmount} €</span> : null}
          </div>
        </div>
      ))}
      {props.cAIOptimizerIsActivated === true ? (
        <div className="row number-block active-block">
          <div className="five columns number-block-title">
            <span className="title">Kosten für collectAI</span>
          </div>
          <div className="seven columns number-block-amount">
            <span className="amount notVisible"></span>
            <span className="CAI-amount">{sepratorFix(props.cAIPrice)} €</span>
          </div>
        </div>
      ) : null}
      <hr />
      <div className="row number-block active-block">
        <div className="five columns number-block-title">
          <span className="title">{sum.title}</span>
        </div>
        <div className="seven columns number-block-amount">
          <span className="amount">{sum.amount} €</span>
          {props.cAIOptimizerIsActivated === true ? <span className="CAI-amount">{sum.cAIAmount} €</span> : null}
        </div>
      </div>
      <div className="row number-block active-block">
        <div className="five columns number-block-title">
          <span className="title">{claimCost.title}</span>
        </div>
        <div className="seven columns number-block-amount">
          <span className="amount">{claimCost.amount} €</span>
          {props.cAIOptimizerIsActivated === true ? <span className="CAI-amount">{(claimCost.cAIAmount)} €</span> : null}
        </div>
      </div>
      {props.cAIOptimizerIsActivated === true ? (
        <div className="row number-block active-block">
          <div className="six columns number-block-title">
            <span className="save-title">Monatliches Einsparpotenzial</span>
          </div>
          <div className="six columns number-block-save">
            <span className="save-amount">{sepratorFix(props.savingPotential)} €</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NumberBlocks;
