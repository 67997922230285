import React from 'react';

const MeetModal = (props) => {
  const { onClose, isModalOpen } = props;

  return (
    <>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={onClose}></button>
            <div className="eight offset-by-two columns">
              <h4> Demo </h4>
              <p>In ihrem Forderungsmanagement steckt unerkanntes Einsparpotenzial</p>
              <p className='flex-content-center'>
                <a className="button button-primary" href="https://meet.collect.ai">
                  Buchen Sie jetzt Ihre Demo!
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MeetModal;
