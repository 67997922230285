const cAIPriceCalculate = (casesPerMonth) => {
  return casesPerMonth > 0 && casesPerMonth <= 7500
    ? 0.95
    : casesPerMonth > 7501 && casesPerMonth <= 12501
    ? 0.94
    : casesPerMonth > 12502 && casesPerMonth <= 20001
    ? 0.89
    : casesPerMonth > 20002 && casesPerMonth <= 30001
    ? 0.84
    : casesPerMonth > 30002 && casesPerMonth <= 42501
    ? 0.79
    : casesPerMonth > 42502 && casesPerMonth <= 57501
    ? 0.74
    : casesPerMonth > 57502 && casesPerMonth <= 75501
    ? 0.69
    : casesPerMonth > 75502 && casesPerMonth <= 95001
    ? 0.64
    : 0.59;
};
export default cAIPriceCalculate;
