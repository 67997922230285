import React, { useState, useEffect, useMemo } from 'react';
import NumberBlocks from './numberBlocks';
import DunningFeesCostsForm from '../forms/dunningDataForm';
import ExportBox from './exportBox';
import SalesDataForm from '../forms/salesDataForm';
import DebtCollectionCostsForm from '../forms/debtCollectionDataForm';
import CAIOptimizerForm from '../forms/cAIOptimizerForm';
import StepIndicator from './stepIndicator';
import RestartCalculations from './restartCalculations';
import defaultData from '../presets/defaultData';
import { initialSettings, initialSetupConfig } from '../presets/initialSettings';
import CAIPriceCalculate from '../presets/cAIpriceList';
import MeetModal from './meetModal';

const CostsCalculator = (props) => {
  const { userEmail } = props;

  const [isMeetModalOpen, setIsMeetModalOpen] = useState(false);

  const [cAIOptimizerIsActivated, setCAIOptimizerIsActivated] = useState(false);
  const selectedIndustryData = defaultData[props.initialData.industry];
  const casesPerMonth = parseFloat(props.initialData.casesPerMonth);
  const claimAVGAmount = parseFloat(props.initialData.claimAVGAmount);
  const dCPShare = parseFloat(props.initialData.dCPShare / 100);

  const [dunningFees, setDunningFees] = useState(selectedIndustryData.dunningFees);
  const [dunningCosts, setDunningCosts] = useState(selectedIndustryData.dunningCosts);

  const [debtCollectionData, setDebtCollectionData] = useState(selectedIndustryData.debtCollectionData);

  const [salesData, setSalesData] = useState(selectedIndustryData.salesData);

  const [cAISettings, setCAISettings] = useState(initialSettings);
  const [cAISetupConfig, setCAISetupConfig] = useState(initialSetupConfig);

  const paymentsCosts = {
    paypal: (parseFloat(cAISettings.paypal) / 100) * dCPShare * claimAVGAmount * 0.25,
    directTransfer: parseFloat(cAISettings.directTransfer) * dCPShare * 0.1,
    sofort: (parseFloat(cAISettings.sofort) / 100) * dCPShare * claimAVGAmount * 0.12,
    creditCard: (parseFloat(cAISettings.creditCard) / 100) * dCPShare * claimAVGAmount * 0.2,
    other: (parseFloat(cAISettings.other) / 100) * dCPShare * claimAVGAmount * 0.2,
    pSP: (parseFloat(cAISettings.pSP) / 100) * dCPShare * claimAVGAmount,
  };

  var activePayments = Object.keys(cAISetupConfig).reduce(function (r, e) {
    if (cAISetupConfig[e] === true) r[e] = paymentsCosts[e];

    return r;
  }, {});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const communicationCosts = {
    email: parseFloat(cAISetupConfig.numberOfEmails) * dCPShare * parseFloat(cAISettings.emailCost),
    sMS: parseFloat(cAISetupConfig.numberOfSMS) * dCPShare * parseFloat(cAISettings.sMScost),
    letter: parseFloat(cAISetupConfig.numberOfLetters) * dCPShare * parseFloat(cAISettings.letterCost),
  };
  const cAIPrice = (parseFloat(CAIPriceCalculate(casesPerMonth)) * casesPerMonth).toFixed(2);

  const calculateDunningCosts = useMemo(() => {
    const sumCosts =
      parseFloat(dunningCosts.communicationCost) +
      parseFloat(dunningCosts.processCost) +
      parseFloat(dunningCosts.callCenterCost) +
      parseFloat(dunningCosts.otherCost);
    return (sumCosts * parseFloat(casesPerMonth)).toFixed(2);
  }, [dunningCosts, casesPerMonth]);

  const calculateCAIDunningCosts = useMemo(() => {
    const cAIProcessCost =
      (parseFloat(dunningCosts.processCost) * dCPShare * (100 - parseFloat(cAISettings.processOpt))) / 100;
    const cAICallCenterCost =
      (parseFloat(dunningCosts.callCenterCost) * dCPShare * (100 - parseFloat(cAISettings.callCenterCostOpt))) / 100;
    const cAIOtherCost = parseFloat(cAISettings.otherCosts);
    const cAICommunicationCosts =
      parseFloat(dunningCosts.communicationCost) * (1 - dCPShare) +
      Object.values(communicationCosts).reduce((a, b) => a + b, 0);
    const cAIPaymentsCosts = Object.values(activePayments).reduce((a, b) => a + b, 0);
    const costPerClaim = cAIProcessCost + cAICallCenterCost + cAIOtherCost + cAICommunicationCosts + cAIPaymentsCosts;

    return (costPerClaim * casesPerMonth).toFixed(2);
  }, [dunningCosts, casesPerMonth, cAISettings, activePayments, communicationCosts, dCPShare]);

  const calculateDunningFees = useMemo(() => {
    return (parseFloat(dunningFees) * casesPerMonth).toFixed(2);
  }, [dunningFees, casesPerMonth]);

  const calculateDebtCollectionCosts = useMemo(() => {
    const debtCollectionCases = (parseFloat(debtCollectionData.debtCollectionShare) / 100) * casesPerMonth;
    return (debtCollectionCases * parseFloat(debtCollectionData.debtCollectionCostPerCase)).toFixed(2);
  }, [debtCollectionData, casesPerMonth]);

  const calculateCAIDebtCollectionCosts = useMemo(() => {
    const cAIDebtCollectionShare =
      (parseFloat(debtCollectionData.debtCollectionShare) / 100) *
      (100 - parseFloat(cAISettings.debtCollectionShareOpt));
    const debtCollectionCase = (cAIDebtCollectionShare / 100) * casesPerMonth;
    return (debtCollectionCase * parseFloat(debtCollectionData.debtCollectionCostPerCase)).toFixed(2);
  }, [cAISettings, casesPerMonth, debtCollectionData]);

  const calculateCACDebtCollection = useMemo(() => {
    const debtCollectionCase = (parseFloat(debtCollectionData.debtCollectionShare) / 100) * casesPerMonth;
    return (debtCollectionCase * parseFloat(debtCollectionData.cAC)).toFixed(2);
  }, [debtCollectionData, casesPerMonth]);

  const calculateCAICACDebtCollection = useMemo(() => {
    const cAIDebtCollectionShare =
      (parseFloat(debtCollectionData.debtCollectionShare) / 100) *
      (100 - parseFloat(cAISettings.debtCollectionShareOpt));
    const debtCollectionCase = (parseFloat(cAIDebtCollectionShare) / 100) * casesPerMonth;
    return (debtCollectionCase * parseFloat(debtCollectionData.cAC)).toFixed(2);
  }, [debtCollectionData, casesPerMonth, cAISettings]);

  const calculateInternalInterest = useMemo(() => {
    return (
      (parseFloat(salesData.capitalRate) / 100 / 365) *
      parseFloat(salesData.daysSalesOutstanding) *
      casesPerMonth *
      claimAVGAmount
    ).toFixed(2);
  }, [salesData, claimAVGAmount, casesPerMonth]);

  const calculateCAIInternalInterest = useMemo(() => {
    const cAIDaysSalesOutstanding =
      (parseFloat(salesData.daysSalesOutstanding) * (100 - parseFloat(cAISettings.daysSalesOutstandingOpt))) / 100;
    return (
      (parseFloat(salesData.capitalRate) / 100 / 365) *
      parseFloat(cAIDaysSalesOutstanding) *
      casesPerMonth *
      claimAVGAmount
    ).toFixed(2);
  }, [salesData, claimAVGAmount, casesPerMonth, cAISettings]);

  const [calculatedAmounts, setCalculatedAmounts] = useState({
    dunningFees: {
      amount: 0,
      cAIAmount: 0,
    },
    dunningCosts: {
      amount: 0,
      cAIAmount: 0,
    },
    salesData: {
      amount: 0,
      cAIAmount: 0,
    },
    debtCollection: {
      amount: 0,
      cAIAmount: 0,
    },
    cACDebtCollection: {
      amount: 0,
      cAIAmount: 0,
    },
  });
  const totalCosts = {
    amount:
      parseFloat(calculatedAmounts.dunningCosts.amount) +
      parseFloat(calculatedAmounts.salesData.amount) +
      parseFloat(calculatedAmounts.debtCollection.amount) +
      parseFloat(calculatedAmounts.cACDebtCollection.amount) -
      parseFloat(calculatedAmounts.dunningFees.amount),
    cAIAmount:
      parseFloat(calculatedAmounts.dunningCosts.cAIAmount) +
      parseFloat(calculatedAmounts.salesData.cAIAmount) +
      parseFloat(calculatedAmounts.debtCollection.cAIAmount) +
      parseFloat(calculatedAmounts.cACDebtCollection.cAIAmount) +
      parseFloat(cAIPrice) -
      parseFloat(calculatedAmounts.dunningFees.cAIAmount),
  };

  const costPerCase = {
    amount: totalCosts.amount / casesPerMonth,
    cAIAmount: totalCosts.cAIAmount / casesPerMonth,
  };
  const savingPotential = (parseFloat(totalCosts.amount) - parseFloat(totalCosts.cAIAmount)).toFixed(2);

  const reinitializeStates = () => {
    setDunningFees(selectedIndustryData.dunningFees);
    setDunningCosts(selectedIndustryData.dunningCosts);
    setDebtCollectionData(selectedIndustryData.debtCollectionData);
    setSalesData(selectedIndustryData.salesData);
    setCAISettings(initialSettings);
    setCAISetupConfig(initialSetupConfig);

    props.setCalculatorStep(0);
    props.setMainSteps(0);
    setCAIOptimizerIsActivated(false);
  };

  const increaseStepLevel = () => {
    props.setCalculatorStep(props.calculatorStep + 1);
    props.calculatorStep === 3 ? setCAIOptimizerIsActivated(true) : setCAIOptimizerIsActivated(false);
  };

  const navigateToStep = (step) => {
    props.setCalculatorStep(step);
  };

  useEffect(() => {
    setCalculatedAmounts({
      dunningFees: {
        amount: calculateDunningFees,
        cAIAmount: calculateDunningFees,
      },
      dunningCosts: {
        amount: calculateDunningCosts,
        cAIAmount: calculateCAIDunningCosts,
      },
      salesData: {
        amount: calculateInternalInterest,
        cAIAmount: calculateCAIInternalInterest,
      },
      debtCollection: {
        amount: calculateDebtCollectionCosts,
        cAIAmount: calculateCAIDebtCollectionCosts,
      },
      cACDebtCollection: {
        amount: calculateCACDebtCollection,
        cAIAmount: calculateCAICACDebtCollection,
      },
    });
  }, [
    calculateDunningFees,
    calculateDunningCosts,
    calculateInternalInterest,
    calculateCACDebtCollection,
    calculateDebtCollectionCosts,
    calculateCAICACDebtCollection,
    calculateCAIDebtCollectionCosts,
    calculateCAIInternalInterest,
    calculateCAIDunningCosts,
  ]);

  return (
    <div className="cost-calculator">
      <StepIndicator
        calculatorStep={props.calculatorStep}
        setCalculatorStep={props.setCalculatorStep}
        onNavigate={navigateToStep}
      />
      <div className="row">
        <div className="five columns">
          {props.calculatorStep === 0 ? (
            <DunningFeesCostsForm
              onClick={increaseStepLevel}
              calculateDunningFees={calculateDunningFees}
              calculateDunningCosts={calculateDunningCosts}
              setCalculatedAmounts={setCalculatedAmounts}
              calculatedAmounts={calculatedAmounts}
              selectedIndustryData={selectedIndustryData}
              dunningFees={dunningFees}
              setDunningFees={setDunningFees}
              dunningCosts={dunningCosts}
              setDunningCosts={setDunningCosts}
            />
          ) : props.calculatorStep === 1 ? (
            <SalesDataForm
              onClick={increaseStepLevel}
              calculateInternalInterest={calculateInternalInterest}
              selectedIndustryData={selectedIndustryData}
              setCalculatedAmounts={setCalculatedAmounts}
              calculatedAmounts={calculatedAmounts}
              setSalesData={setSalesData}
              salesData={salesData}
            />
          ) : props.calculatorStep === 2 ? (
            <DebtCollectionCostsForm
              onClick={increaseStepLevel}
              calculateDebtCollectionCosts={calculateDebtCollectionCosts}
              calculateCACDebtCollection={calculateCACDebtCollection}
              selectedIndustryData={selectedIndustryData}
              setCalculatedAmounts={setCalculatedAmounts}
              calculatedAmounts={calculatedAmounts}
              debtCollectionData={debtCollectionData}
              setDebtCollectionData={setDebtCollectionData}
            />
          ) : (
            <CAIOptimizerForm
              cAISettings={cAISettings}
              cAISetupConfig={cAISetupConfig}
              setCAISettings={setCAISettings}
              setCAISetupConfig={setCAISetupConfig}
              setCalculatedAmounts={setCalculatedAmounts}
              calculatedAmounts={calculatedAmounts}
              calculateCAIDunningCosts={calculateCAIDunningCosts}
              calculateCAIInternalInterest={calculateCAIInternalInterest}
              calculateCAIDebtCollectionCosts={calculateCAIDebtCollectionCosts}
              calculateCAICACDebtCollection={calculateCAICACDebtCollection}
            />
          )}
        </div>
        <div className="seven columns">
          <NumberBlocks
            calculatedAmounts={calculatedAmounts}
            cAIOptimizerIsActivated={cAIOptimizerIsActivated}
            setCAIOptimizerIsActivated={setCAIOptimizerIsActivated}
            totalCosts={totalCosts}
            costPerCase={costPerCase}
            cAIPrice={cAIPrice}
            calculatorStep={props.calculatorStep}
            savingPotential={savingPotential}
          />
          {props.calculatorStep === 3 ? (
            <React.Fragment>
              <MeetModal isModalOpen={isMeetModalOpen} onClose={() => setIsMeetModalOpen(false)} />
              <ExportBox
                userEmail={userEmail}
                casesPerMonth={casesPerMonth}
                claimAVGAmount={claimAVGAmount}
                dCPShare={dCPShare}
                dunningFees={dunningFees}
                dunningCosts={dunningCosts}
                salesData={salesData}
                debtCollectionData={debtCollectionData}
                calculatedAmounts={calculatedAmounts}
                cAIPrice={cAIPrice}
                totalCosts={totalCosts}
                costPerCase={costPerCase}
                savingPotential={savingPotential}
                activePayments={activePayments}
                cAISetupConfig={cAISetupConfig}
                onDownload={() => {
                  setIsMeetModalOpen(true);
                }}
              />
              <RestartCalculations onRestart={reinitializeStates} />
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CostsCalculator;
